// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';
import 'counterup/jquery.counterup.min';
// import scrollify from 'scrollify';
// import 'waypoints/lib/jquery.waypoints.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';
/*global ajax_object*/
/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

function fixedHeader() {
  let headerHeight = $('header').outerHeight();
  $('body').css('padding-top', headerHeight);
}

function setHeroWhiteBox(currentSlide) {
  let x = $('.gallery-hero-navigation#' + currentSlide).position();
  // console.log(x);
  if (typeof x != 'undefined') {
    let leftPosition = x.left;
    // console.log(currentSlide);
    $('.current-hero-dot').css('left', leftPosition + 8);
  }
}

function setWhiteBox(currentSlide) {
  // console.log($('img#1'));
  let x = $('img#' + currentSlide).position();
  if ($(window).width() > 640) {
    if (typeof x != 'undefined') {
      let leftPosition = x.left;
      // console.log(currentSlide);
      $('.current-dot').css('left', leftPosition + 5);
    }
  } else {
    if (typeof x != 'undefined') {
      let leftPosition = x.left;
      // console.log(leftPosition);
      $('.current-dot').css('left', leftPosition + 24);
      // console.log($('.current-dot').position());
    }
  }
}

function caseStudySwitch() {
  if ($(window).width() > 780) {
    if ($('.case-studies--bottom').hasClass('slick-initialized')) {
      $('.case-studies--bottom').slick('unslick');
      return;
    }
    $('.case-studies__item, .case-studies__item-text')
      .mouseenter(function () {
        let itemId = $(this).attr('id');
        $('.case-studies__item')
          .not('#' + itemId)
          .addClass('hidden');
        $('.case-studies__item#' + itemId).addClass('active');
        $('.case-studies__item-text#' + itemId).addClass('active');
      })
      .mouseleave(function () {
        let itemId = $(this).attr('id');
        $('.case-studies__item')
          .not('#' + itemId)
          .removeClass('hidden');
        $('.case-studies__item#' + itemId).removeClass('active');
        $('.case-studies__item-text#' + itemId).removeClass('active');
      });
  } else {
    if ($('.case-studies--bottom').hasClass('slick-initialized')) {
      return;
    } else {
      $('.case-studies--bottom').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        prevArrow: $('.slick-prev'),
        nextArrow: $('.slick-next'),
        infinite: false,
      });
    }
  }
}

function tilesSlider() {
  if ($(window).width() < 900) {
    if ($('.image-repeater-bottom').hasClass('slick-initialized')) {
      return;
    } else {
      $('.image-repeater-bottom').slick({
        slidesToShow: 3,
        dots: false,
        arrows: false,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 750,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 420,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      });
    }
  } else {
    if ($('.image-repeater-bottom').hasClass('slick-initialized')) {
      $('.image-repeater-bottom').slick('unslick');
    }
  }
}

function setNestedSliderHeight() {
  let leftPartHeight = $(document)
    .find('.nested-products-slider--wrapper')
    .height();
  if ($(window).width() > 1000) {
    $('.nested-products-slider-right__gallery').css('height', leftPartHeight);
  }
}

//Contractor ajax loadmore
function loadMore(paged, activeCategory, postType) {
  // let activeCategory = $('.contractor-category').filter('.active');
  // console.log(paged);
  // console.log(activeCategory);
  // console.log(postType);
  $.ajax({
    type: 'POST',
    url: ajax_object.ajax_url,
    dataType: 'json',
    data: {
      action: 'single_category_loadmore',
      filterCategory: activeCategory,
      postType: postType,
      paged,
    },
    beforeSend: function () {
      $('#load-more').text('Loading');
    },
    success: function (response) {
      if (paged >= response.max) {
        $('.load-more').hide();
      }
      // console.log(response);
      // console.log(response.taxonomy);
      $(response.html).hide().insertBefore('.load-more').slideDown('fast');
      // $('.conractor-tiles').append().slideDown();
      $('#load-more').text('More');
    },
    error: function (response) {
      console.log(response.html);
    },
  });
}

function singleCategoryFilter(postType, categoryFilter) {
  // console.log(postType);
  // console.log(categoryFilter);
  $.ajax({
    type: 'POST',
    url: ajax_object.ajax_url,
    dataType: 'html',
    data: {
      action: 'single_category_filter',
      filterCategory: categoryFilter,
      postType: postType,
    },
    success: function (res) {
      $('.filter-tiles').hide().html(res).slideDown('fast');
      // console.log(res);
    },
    error: function () {
      $('.filter-tiles')
        .hide()
        .html('<h4>No cases found...</h4>')
        .slideDown('fast');
    },
  });
}

function caseStudiesfilter(
  currentLocation,
  currentProduct,
  currentFilter,
  taxName
) {
  // console.log(currentLocation);
  // console.log(currentProduct);
  // console.log(currentFilter);
  // console.log(taxName);
  $.ajax({
    type: 'POST',
    url: ajax_object.ajax_url,
    dataType: 'json',
    data: {
      action: 'case_filter',
      location: currentLocation,
      product: currentProduct,
      currentFilter: currentFilter,
      taxName: taxName,
    },
    success: function (response) {
      $('.filter-tiles').hide().html(response.html).slideDown('fast');
      let buttonCurrent = $('#cancel-button.' + response.button_class);
      // console.log(response.remove_button);
      // console.log(response.button_class);
      // console.log(buttonCurrent);
      if (response.remove_button === true) {
        buttonCurrent.remove();
      } else if (buttonCurrent.length > 0) {
        buttonCurrent.replaceWith(response.buttons);
      } else {
        $('.cases-filtration__categories--buttons')
          .hide()
          .append(response.buttons)
          .slideDown('fast');
      }
      // console.log(res);
      $('.first-filter').val('all').change();
      $('.second-filter').val('all').change();
    },
    error: function () {
      console.log('error');
    },
  });
}

function newsFilter(currentNews, currentProduct, currentFilter, taxName) {
  // console.log(currentNews);
  // console.log(currentProduct);
  // console.log(currentFilter);
  // console.log(taxName);
  $.ajax({
    type: 'POST',
    url: ajax_object.ajax_url,
    dataType: 'json',
    data: {
      action: 'news_filter',
      news: currentNews,
      product: currentProduct,
      currentFilter: currentFilter,
      taxName: taxName,
    },
    success: function (response) {
      $('.filter-tiles').hide().html(response.html).slideDown('fast');
      $('.filter-tiles').append(response.load_more);
      let buttonCurrent = $('#news-cancel-button.' + response.button_class);
      // console.log(response.remove_button);
      // console.log(response.button_class);
      // console.log(buttonCurrent);
      if (response.remove_button === true) {
        buttonCurrent.remove();
      } else if (buttonCurrent.length > 0) {
        buttonCurrent.replaceWith(response.buttons);
      } else {
        $('.news-filtration__categories--buttons')
          .hide()
          .append(response.buttons)
          .slideDown('fast');
      }
      // console.log(res);
      $('.filter-products').val('all').change();
      $('.filter-news').val('all').change();
    },
    error: function () {
      console.log('error');
    },
  });
}

function newsLoadMore(currentProduct, currentNews, paged) {
  // console.log(currentProduct);
  // console.log(currentNews);
  // console.log(paged);
  $.ajax({
    type: 'POST',
    url: ajax_object.ajax_url,
    dataType: 'json',
    data: {
      action: 'news_loadmore',
      news: currentNews,
      product: currentProduct,
      paged,
    },
    beforeSend: function () {
      $('#news-more').text('Loading');
    },
    success: function (response) {
      if (paged >= response.max) {
        $('.load-more').hide();
      }
      // console.log(response);
      // console.log(response.taxonomy);
      $(response.html).hide().insertBefore('.load-more').slideDown('fast');
      // $('.conractor-tiles').append().slideDown();
      $('#news-more').text('More');
    },
    error: function (response) {
      console.log(response.html);
    },
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  // var waypoint = new Waypoint({
  //   element: document.getElementById('basic-waypoint'),
  //   handler: function () {
  // });
  $('.number-counterup').counterUp({
    delay: 10,
    time: 1500,
  });
  $('.text-repeater').waypoint(function () {
    // setTimeout(function () {
    //   $('.number-more').append('+');
    // }, 1500);
    // this.destroy();
  });
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });
  $('[data-fancybox="gallery"]').fancybox({
    idleTime: 60,
    infobar: false,
    buttons: [
      // "zoom",
      //"share",
      // "slideShow",
      //"fullScreen",
      //"download",
      // "thumbs",
      'close',
    ],
    btnTpl: {
      close:
        '<button data-fancybox-close class="fancybox-button--close" title="{{CLOSE}}">' +
        '<p>X</p>' +
        '</button>',
      // Arrows
      arrowLeft:
        '<button data-fancybox-prev class="fancybox-arrow_left" title="{{PREV}}">' +
        '<div></div>' +
        '</button>',

      arrowRight:
        '<button data-fancybox-next class="fancybox-arrow_right" title="{{NEXT}}">' +
        '<div></div>' +
        '</button>',
    },
    beforeShow: function () {
      if ($(window).width() < 780) {
        $('.fancybox-image').css({
          height: '100%',
          width: '100%',
          'object-fit': 'cover',
        });
        // set size for parent container
        (this.width = 351), (this.height = 351);
      }
    },
  });
  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here
  setHeroWhiteBox(0);

  fixedHeader();
  $('.products-slider__list').slick({
    slidesToShow: 2,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    prevArrow: $('.prev'),
    nextArrow: $('.next'),
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });
  $('.product-single-hero__slider').slick({
    // autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    autoplay: true,
    autoplaySpeed: 2000,
  });
  $('.gallery-hero-navigation').on('click', function () {
    let di = $(this).data('index');
    $(this).addClass('active');
    $('.product-single-hero__slider').slick('slickGoTo', di);
    let x = $(this).position();
    let leftPosition = x.left;
    $('.current-hero-dot').css('left', leftPosition + 8);
  });
  $('.product-single-hero__slider').on(
    'beforeChange',
    function (event, slick, currentSlide, nextSlide) {
      // console.log(nextSlide);

      // console.log(currentSlide);
      let x = $('.gallery-hero-navigation#' + nextSlide).position();
      // console.log(x);
      let leftPosition = x.left;
      if ($(window).width() > 640) {
        $('.current-hero-dot').css('left', leftPosition + 8);
      } else {
        $('.current-hero-dot').css('left', leftPosition + 24);
        // console.log(
        //   $('.current-hero-dot')
        //     .css('left', leftPosition + 24)
        //     .position()
        // );
      }
    }
  );
  setWhiteBox(0);
  caseStudySwitch();
  $('.nested-products-slider-right__gallery').each(function () {
    // console.log(this);
    $(this).slick({
      // autoplay: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipe: false,
      arrows: false,
      fade: true,
      autoplay: true,
      // infinite: true,
      autoplaySpeed: 2000,
    });
  });
  $('.nested-products-slider-right').each(function () {
    let navigationId = $(this).attr('data-slider');
    let navigation = $(this).find(
      '.gallery-dots[data-slider = ' + navigationId + ']'
    );
    $(this)
      .find('.nested-products-slider-right__gallery')
      .on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        // console.log(nextSlide);
        // console.log(navigation);
        let x = navigation.find('.gallery-navigation#' + nextSlide).position();
        // console.log(x);
        let leftPosition = x.left;
        if ($(window).width() > 640) {
          $('.current-dot[data-slider = ' + navigationId + ']').css(
            'left',
            leftPosition + 8
          );
        } else {
          $('.current-dot[data-slider = ' + navigationId + ']').css(
            'left',
            leftPosition + 24
          );
        }
      });
  });
  $('.gallery-navigation').on('click', function () {
    let di = $(this).data('index');
    let parentId = $(this).parent().attr('data-slider');
    // console.log(di);
    $(this).addClass('active');
    $(
      '.nested-products-slider-right__gallery[data-slider = ' + parentId + ']'
    ).slick('slickGoTo', di);
  });
  $('.menu-icon[aria-controls="main-menu"]').off('click');
  $('.menu-icon').on('click', function () {
    $(this).toggleClass('is-active');
    $('#main-menu').slideToggle();
  });
  //
  //CONTRACTOR FILTRATION
  //
  let currentPage = 2;
  $('.contractor-category').on('click', function () {
    // $('.contractor-category').removeClass('active');
    $(this).toggleClass('active');
    // let categories = $(this).data('slug');
    let categories = new Array();
    $('.contractor-category').each(function () {
      if ($(this).hasClass('active')) {
        categories.push($(this).data('slug'));
      }
    });
    // console.log(categories);
    if (categories.length === 0) {
      $('.contractor-category').each(function () {
        categories.push($(this).data('slug'));
      });
    }
    singleCategoryFilter('contractor', categories);
    currentPage = 2;
  });
  //
  // GALLERY FILTRATION
  //
  $('.gallery-category').on('click', function () {
    $(this).toggleClass('active');
    // let category = $(this).data('slug');
    // console.log(this);
    let categories = new Array();
    $('.gallery-category').each(function () {
      if ($(this).hasClass('active')) {
        categories.push($(this).data('slug'));
      }
    });
    // console.log(categories);
    if (categories.length === 0) {
      $('.gallery-category').each(function () {
        categories.push($(this).data('slug'));
      });
    }
    singleCategoryFilter('gallery', categories);
    currentPage = 2;
  });
  //CONTRACTOR AND GALLERY LOADMORE
  $(document).on('click', '#load-more', function () {
    // Do currentPage + 1, because we want to load the next page
    // console.log(currentPage);
    // let currentCategories = $(document).find('.category.active').data('slug');
    let currentCategories = new Array();
    $('.category.active').each(function () {
      if ($(this).hasClass('active')) {
        currentCategories.push($(this).data('slug'));
      }
    });
    if (currentCategories.length === 0) {
      currentCategories = 'all';
    }
    let currentPostType = $(document).find('.filter-tiles').attr('id');
    // console.log(currentCategory);
    // console.log(currentPostType);
    loadMore(currentPage, currentCategories, currentPostType);
    currentPage++;
  });
  //
  //NEWS AND RESOURCES
  //
  $('.filter-products').on('change', function () {
    let currentProduct = $(this).find(':selected').val();
    if (currentProduct == 'all') {
      return;
    } else {
      let currentNews = $(document).find('.news').val();
      if (typeof currentNews === 'undefined') {
        currentNews = 'all';
      }
      let taxName = $(this).attr('id');
      newsFilter(currentNews, currentProduct, currentProduct, taxName);
      currentPage = 2;
    }
  });
  $('.filter-news').on('change', function () {
    let currentNews = $(this).find(':selected').val();
    if (currentNews == 'all') {
      return;
    } else {
      let currentProduct = $(document).find('.products').val();
      if (typeof currentProduct === 'undefined') {
        currentProduct = 'all';
      }
      let taxName = $(this).attr('id');
      // console.log(currentProduct);
      newsFilter(currentNews, currentProduct, currentNews, taxName);
      currentPage = 2;
    }
  });
  $(document).on('click', '#news-cancel-button.products', function () {
    let CurrentCategory = $(this).attr('class');
    let otherCategory = $(document).find('#cancel-button.location').val();
    if (typeof otherCategory === 'undefined') {
      otherCategory = 'all';
    }
    newsFilter(otherCategory, 'all', false, CurrentCategory);
    currentPage = 2;
  });
  $(document).on('click', '#news-cancel-button.news', function () {
    let CurrentCategory = $(this).attr('class');
    let otherCategory = $(document).find('#cancel-button.products').val();
    if (typeof otherCategory === 'undefined') {
      otherCategory = 'all';
    }
    newsFilter('all', otherCategory, false, CurrentCategory);
    currentPage = 2;
  });
  $(document).on('click', '#news-more', function () {
    let currentProduct = $(document).find('.products').val();
    if (typeof currentProduct === 'undefined') {
      currentProduct = 'all';
    }
    let currentNews = $(document).find('.news').val();
    if (typeof currentNews === 'undefined') {
      currentNews = 'all';
    }
    newsLoadMore(currentProduct, currentNews, currentPage);
    currentPage++;
  });
  ////////////////////////////////////////
  ////////////////////////////////////////
  ////////////////////////////////////////
  $('.first-filter').on('change', function () {
    let currentProduct = $(this).find(':selected').val();
    if (currentProduct == 'all') {
      return;
    } else {
      let currentLocation = $(document).find('.location').val();
      if (typeof currentLocation === 'undefined') {
        currentLocation = 'all';
      }
      let taxName = $(this).attr('id');
      caseStudiesfilter(
        currentLocation,
        currentProduct,
        currentProduct,
        taxName
      );
    }
  });
  $('.second-filter').on('change', function () {
    let currentLocation = $(this).find(':selected').val();
    if (currentLocation == 'all') {
      return;
    } else {
      let currentProduct = $(document).find('.products').val();
      if (typeof currentProduct === 'undefined') {
        currentProduct = 'all';
      }
      let taxName = $(this).attr('id');
      // console.log(currentProduct);
      caseStudiesfilter(
        currentLocation,
        currentProduct,
        currentLocation,
        taxName
      );
    }
  });
  $(document).on('click', '#cancel-button.products', function () {
    let CurrentCategory = $(this).attr('class');
    let otherCategory = $(document).find('#cancel-button.location').val();
    if (typeof otherCategory === 'undefined') {
      otherCategory = 'all';
    }
    caseStudiesfilter(otherCategory, 'all', false, CurrentCategory);
  });
  $(document).on('click', '#cancel-button.location', function () {
    let CurrentCategory = $(this).attr('class');
    let otherCategory = $(document).find('#cancel-button.products').val();
    if (typeof otherCategory === 'undefined') {
      otherCategory = 'all';
    }
    caseStudiesfilter('all', otherCategory, false, CurrentCategory);
  });
  $(document)
    .find('.heading-line')
    .wrap("<div class='heading-line__wrapper'></div>");
  $(document)
    .find('.site-button')
    .wrap(
      "<div class='custom-button large-button'><div class='custom-button__wrapper'></div></div>"
    );
  $('.text-image').each(function () {
    $(this)
      .next('p')
      .andSelf()
      .wrapAll("<div class='text-image__wrapper'></div>");
  });
  if ($(window).width() > 780) {
    $('.text-image__wrapper').each(function () {
      let textImageHeight = $(this).find('p').height();
      // console.log(textImageHeight);
      $(this).css('margin-bottom', textImageHeight + 30);
    });
  } else {
    $('.text-image__wrapper').each(function () {
      let textImageHeight = $(this).find('p').height();
      // console.log(textImageHeight);
      $(this).css('margin-bottom', textImageHeight + 59);
    });
  }

  $('.submenu-toggle').on('click', function () {
    let currentMenuId = $(this).attr('id');
    let currentMenuItem = $(document).find('li#' + currentMenuId);
    if (currentMenuItem.find('a').hasClass('active-link')) {
      $('.header-menu').find('a').removeClass('active-link');
    } else {
      $('.header-menu').find('a').removeClass('active-link');
      currentMenuItem.find('a').toggleClass('active-link');
    }
  });

  $('#video_play').on('click', function () {
    $('.news-video__front').addClass('inactive');
    $('video')[0].play();
    $('#video_play').addClass('hide');
    // console.log(this);
  });
  setNestedSliderHeight();
  tilesSlider();
  if ($(window).width() > 1024) {
    $.scrollify({
      section: '.nested-products-slider-slide',
      sectionName: 'data-section',
      scrollSpeed: 1000,
      // standardScrollElements: '.nested-products-slider-left',
      // scrollbars: true,
      before: function (i, panels) {
        var ref = panels[i].attr('id');

        $('.pagination .active').removeClass('active');

        $('.pagination')
          .find('a[href="#' + ref + '"]')
          .addClass('active');
      },
      afterRender: function () {
        var pagination =
          '<div class="nested_pagination"><ul class="pagination">';
        var activeClass = '';
        $('.nested-products-slider-slide').each(function (i) {
          activeClass = '';
          if (i === $.scrollify.currentIndex()) {
            activeClass = 'active';
          }
          pagination +=
            '<li><a class="' +
            activeClass +
            '" href="#' +
            $(this).attr('id') +
            '"><span class="custom-dot"></span><span class="hover-text">' +
            $(this).attr('data-section-name').charAt(0).toUpperCase() +
            $(this).attr('data-section-name').slice(1) +
            '</span></a></li>';
        });

        pagination += '</ul></div>';
        // console.log(pagination);
        $('.nested-slider').prepend(pagination);
        /*

        Tip: The two click events below are the same:

        $(".pagination a").on("click",function() {
          $.scrollify.move($(this).attr("href"));
        });

        */
        $('.pagination a').on('click', $.scrollify.move);
      },
    });
  }
  $('#menu-item-37>a').on('click', function () {
    // $(this).find('ul').slideDown('fast');
    // $('#menu-header-menu').foundation('_show', $('#menu-item-37'));
    $('#n9k2gn-acc-menu').foundation('toggleMenu');
  });
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here
  resizeVideo();
  fixedHeader();
  caseStudySwitch();
  setNestedSliderHeight();
  setWhiteBox(0);
  tilesSlider();
  if ($(window).width() > 1024) {
    if ($.scrollify.isDisabled() == true) {
      $.scrollify({
        section: '.nested-products-slider-slide',
        sectionName: 'data-section',
        scrollSpeed: 1000,
        // standardScrollElements: '.nested-products-slider-left',
      });
      // console.log('test');
    } else {
      return;
    }
  } else {
    if ($.scrollify.isDisabled() == true) {
      return;
    } else {
      $.scrollify.disable();
    }
  }
});

/**
 * Scripts which runs on scrolling
 */
if ($('.filtration-categories').length > 0) {
  const categoriesTop = $('.filtration-categories').offset().top;
  const categoriesHeight = $('.filtration-categories').outerHeight();
  const headerHeight = $('.header').outerHeight();
  $(window).on('scroll', function () {
    // if ($(document).scrollTop() > categoriesTop) {
    //   $('.header').slideUp('fast');
    // } else {
    //   $('.header').slideDown('fast');
    // }
    if ($(document).scrollTop() >= categoriesTop + headerHeight) {
      $('.filtration-categories').addClass('fixed-category');
      $('.filter-tiles').css('margin-top', categoriesHeight + 30);
    } else {
      $('.filtration-categories').removeClass('fixed-category');
      $('.filter-tiles').css('margin-top', 30);
    }
  });
}
// if ($('.nested-slider').length > 0) {
//   const sliderTop = $('.nested-slider').offset().top;
//   const lastSLide = $('.nested-products-slider-slide:last-child').offset().top;
//   // const categoriesHeight = $('.filtration-categories').outerHeight();
//   // const headerHeight = $('.header').outerHeight();
//   $(window).on('scroll', function () {
//     console.log(sliderTop);
//     console.log(lastSLide);
//     if ($(document).scrollTop() > sliderTop) {
//       $('.custom-dots').addClass('fixed');
//     }
//     if ($(document).scrollTop() > lastSLide) {
//       $('.custom-dots').removeClass('fixed');
//     }
//   });
// }
// const headerTop = $('.header').offset();
let lastScrollTop = 0;
$(window).on('scroll', function () {
  // jQuery code goes here
  let st = $(this).scrollTop();
  // console.log(st - lastScrollTop);
  // console.log(st);
  if (st - lastScrollTop > 80) {
    if ($(window).width() < 1024) {
      if ($('.top-bar').css('display') === 'block') {
        return;
      } else {
        $('.header').slideUp('fast');
      }
    } else {
      $('.header').slideUp('fast');
    }
    // if ($('.top-bar').css('display') === 'block') {
    //   return;
    // } else {
    //   $('.header').slideUp('fast');
    // }
  } else if (st - lastScrollTop < -80) {
    if ($(window).width() < 1024) {
      if ($('.top-bar').css('display') === 'block') {
        return;
      } else {
        $('.header').slideDown('fast');
      }
    } else {
      $('.header').slideDown('fast');
    }
  } else {
    return;
  }
  lastScrollTop = st;
});
